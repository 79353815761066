import classes from "./ConfirmOrder.module.scss";
import { CSSProperties, RefObject, useMemo } from "react";
import { formatPrice, isNumber } from "../../common/price/price";
import { Typography } from "@material-ui/core";
import React from "react";

export type PaymentRowOptions = {
  percent?: number;
  isDiscount?: boolean;
  isPositive?: boolean;
  alternated?: boolean;
  bold?: boolean;
  count?: number;
  replaceAmountLabel?: string;
}

type PaymentRowProps = {
  label: string;
  value?: number | string | null | undefined;
  percent?: number;
  isDiscount?: boolean;
  isPositive?: boolean;
  alternated?: boolean;
  bold?: boolean;
  count?: number;
  replaceAmountLabel?: string;
  warning?: boolean;
};

export const PaymentRow = React.forwardRef<any, PaymentRowProps>((props: PaymentRowProps, ref) => {
  const { label, value = 0, percent, isDiscount, isPositive,
    alternated, bold, count, replaceAmountLabel, warning } = props;

  const style: CSSProperties = {
    fontWeight: !bold ? "initial" : "900"
  }

  const RightColumnText = useMemo(() => {
    let rightText = "";
    if (!!percent) {
      rightText = `(${Math.abs(percent)}%)`;
    }

    if (alternated && count) {
      rightText = `(${count?.toString()})`;
    }

    return <Typography className={classes.rightText} style={style}>{rightText}</Typography>;
  }, [percent]);

  const AmountText = useMemo(() => {
    let rowText = "";
    const formattedValue = isNumber(value) ? formatPrice(value as number) : value;
    if (alternated && formattedValue)
      rowText = formattedValue?.toString();
    else
      rowText = `${isDiscount ? "-" : ""} ${formattedValue}`;

    if (+value! === 0 && !!replaceAmountLabel) {
      rowText = replaceAmountLabel;
    }

    return <Typography className={classes.value} style={style}>{rowText}</Typography>
  }, [value, count, alternated, isDiscount, replaceAmountLabel]);

  let textColor: React.CSSProperties["color"] = undefined;
  if (isDiscount === true || isPositive === true) {
    textColor = "green";
  }

  if (isDiscount === false) {
    textColor = "red";
  }

  if (warning === true) {
    textColor = "rgb(255, 160, 0)";
  }

  if (value === 0) {
    return null;
  }

  return (
    <div
      ref={ref}
      key={label}
      className={classes.price}
      style={{
        color: textColor,
      }}
    >
      <Typography className={classes.label} style={style}>{label}</Typography>
      {AmountText}
      {RightColumnText}
    </div>
  );
});
