import { OrderMode } from './../../constants';
import axios from "axios";
import { Dispatch } from "redux";
import { baseUrl } from "../../serverConfig";
import { DeliveryInfo, TrackOrderPayload } from "../../Types/Types";
import { ActionType } from "../action-types";
import { Action } from "../actions";


const sendOrderToServer = (order: TrackOrderPayload) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.put(`${baseUrl}/rest/order`, order);

      dispatch({
        type: ActionType.SEND_ORDER_SUCCESS,
        payload: data,
      });

    } catch (err) {
      dispatch({
        type: ActionType.SEND_ORDER_ERROR,
        payload: err.message,
      });
    }

  };
};

export const setOrderMode = (appViewMode?: OrderMode) => {
  return {
    type: ActionType.SET_ORDER_MODE,
    payload: appViewMode
  }
}


export const setOrderUserId = (userId: number) => {
  return {
    type: ActionType.SET_ORDER_USER_ID,
    payload: userId,
  }
}

export const setPaperBagCount = (count: number) => {
  return {
    type: ActionType.SET_PAPER_BAG_COUNT,
    payload: count,
  }
}

export const setPaperBagFee = (fee: number) => {
  return {
    type: ActionType.SET_PAPER_BAG_FEE,
    payload: fee,
  }
}

export const setDeliveryInfo = (deliveryInfo: DeliveryInfo) => {
  return {
    type: ActionType.SET_DELIVERY_INFO,
    payload: deliveryInfo
  }
}


export default sendOrderToServer;