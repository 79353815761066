import classes from "./Checkout.module.scss";
import { useEffect, useMemo, useRef } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useParams } from "react-router";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { getCookie } from "../../common/cookies";
import { isNumber } from "../../common/price/price";
import {
  OrderMode,
  OptionType,
  StoreOrderingState,
  userPhoneCookieKey,
} from "../../constants";
import { getOrderMode } from "../../common/app";
import { TextField, Typography } from "@material-ui/core";
import CateringDateTimeDisplay from "../Catering/CateringDateTimeDisplay";
import DateTimeFields from "./DateTimeFields";
import PromoCombo from "./PromoCombo";
import PaperbagDropdown from "./ParperBagDropdown";
import PhoneFieldCombo, { SendSmsCheckbox } from "./PhoneFieldCombo";
import DeliveryFields from "./DeliveryFields";
import NameFieldCombo from "./NameFieldCombo";
import Tips from "../Tips/Tips";

type CheckoutDetailProps = {
  handleNext: Function;
  handleBack: Function;
};

const CheckoutDetail = (props: CheckoutDetailProps) => {
  const { handleNext, handleBack } = props;
  const { viewMode } = useParams<IPublicProfileRouteParams>();
  const finalOrder = useTypedSelector((state) => state.orderDetails.order);
  const appViewMode = getOrderMode(viewMode);
  const isCateringViewMode = appViewMode === OrderMode.catering;
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const formDivRef = useRef<HTMLDivElement | null>(null);
  const {
    setSpecialNotes,
    setUserNumber,
    setPreOrderDateTime,
    setTipPercentage,
  } = useActions();

  const isPreOrderOnly =
    businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;
  const isTableViewMode = isPreOrderOnly ? false : isNumber(viewMode);

  const hasOnlyGiftCardsInCart = finalOrder.finalOrderItems.every(
    (item) => item.type === OptionType.giftCard
  );

  const isDeliveryOrder = finalOrder.orderType === "delivery";
  const isCustomerContactRequired = useMemo(() => {
    if (isDeliveryOrder) return true;
    return businessInfo?.customerContactInfo === "required";
  }, [businessInfo?.customerContactInfo, isDeliveryOrder]);

  useEffect(() => {
    if (!isCateringViewMode) {
      setPreOrderDateTime(undefined);
    }

    if (hasOnlyGiftCardsInCart) {
      setTipPercentage(0);
    }

    const cookieUserPhone = getCookie(userPhoneCookieKey);
    if (cookieUserPhone) {
      setUserNumber(cookieUserPhone);
    }
  }, []);

  return (
    <div className={`checkout ${classes.checkout}`}>
      <section className={classes.body}>
        <div className={classes.form} ref={formDivRef}>
          {isTableViewMode && (
            <Typography variant="h5" style={{ fontWeight: 500 }}>
              Table #{viewMode}
            </Typography>
          )}

          <CateringDateTimeDisplay hideEditButton />

          {!isTableViewMode && (
            <>
              <DeliveryFields />

              <DateTimeFields />

              <div className={classes.formRow}>
                <NameFieldCombo />

                <PhoneFieldCombo />
              </div>

              <SendSmsCheckbox />
            </>
          )}

          <TextField
            label="Special requests (opt)"
            variant="standard"
            multiline
            className={classes.textField}
            inputProps={{ maxLength: 150 }}
            maxRows={2}
            helperText="Is there anything we need to know to prepare your order?"
            onChange={(e) => setSpecialNotes(e.target.value)}
            defaultValue={
              finalOrder.instructions ? finalOrder.instructions : ""
            }
          />

          <PromoCombo />

          <PaperbagDropdown />

          {!hasOnlyGiftCardsInCart && <Tips />}
        </div>
      </section>
    </div>
  );
};

export default CheckoutDetail;
