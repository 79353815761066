import classes from "./Checkout.module.scss";
import { deliveryAddressPlaceholder, deliveryInfoCookieKey } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CheckoutFormStatus, DeliveryInfo, DestinationStatus } from "../../Types/Types";
import Loader from "../Loader/Loader";
import { getCookie, setCookie } from "../../common/cookies";
import { TextField } from "@material-ui/core";
import { useDeliveryAddress } from "./hooks/useDeliveryAddress";

(window as any).initMap = () => { }

export const GoogleAutocomplete = () => {
    const eventName = "place_changed";

    const { setDeliveryInfo, updateCheckoutFormStatus } = useActions();

    const finalOrder = useTypedSelector((state) => state.orderDetails.order);
    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const { checkoutFormStatus } = useTypedSelector((state) => state.appLocalStatus);
    const destinationStatus = finalOrder.deliveryAddress.destinationStatus;
    const deliveryAddress = finalOrder.deliveryAddress;
    const { ValidateDestination, createAutoCompleteListener } = useDeliveryAddress();

    const [addressInput, setAddressInput] = useState<HTMLInputElement | null>(null);

    useEffect(() => {

        const storageDeliveryInfo = getCookie(deliveryInfoCookieKey);
        if (!!storageDeliveryInfo) {
            const deliveryInfo = JSON.parse(storageDeliveryInfo) as DeliveryInfo;
            setDeliveryInfo(deliveryInfo);
            if (addressInput && deliveryInfo.completeAddress) {
                addressInput.value = deliveryInfo.completeAddress;
            }
        }

    }, [addressInput]);

    const createGoogleAutoComplete = useCallback(() => {
        if (!addressInput) return null;

        const ac = new google.maps.places.Autocomplete(addressInput, {
            componentRestrictions: { country: ["us", "ca"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
        });

        ac.addListener(eventName, createAutoCompleteListener(ac));

        return ac;
    }, [addressInput]);

    const [autocomplete] = useState(createGoogleAutoComplete());


    const hasError = destinationStatus === DestinationStatus.error || checkoutFormStatus === CheckoutFormStatus.missingFields;

    return <div>
        <TextField
            required
            label="Address"
            type="text"
            autoComplete="off"
            error={hasError}
            className={classes.textField}
            inputRef={r => setAddressInput(r)}
            defaultValue={deliveryAddress?.completeAddress}
            placeholder={deliveryAddressPlaceholder}
            inputProps={{
                maxLength: 100,
                onInput: (e) => {
                    if (!e.currentTarget.value) {
                        setDeliveryInfo({
                            ...deliveryAddress,
                            destinationStatus: DestinationStatus.standby,
                            completeAddress: ""
                        });
                    } else {
                        updateCheckoutFormStatus(CheckoutFormStatus.standby);
                    }
                }
            }}>
        </TextField>
    </div>;
};
