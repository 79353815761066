import classes from "./Tips.module.scss";
import useTheme from '@mui/material/styles/useTheme';
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { grey } from "@material-ui/core/colors";
import { useCallback, useMemo } from "react";
import { FormControl, InputAdornment, TextField, Typography } from "@material-ui/core";
import { NumberMaskInput } from "./NumberMaskInput";

const Tips = () => {
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const selectedTip = useTypedSelector((state) => state.orderDetails.order.selectedTipPercentage);
  const { setTipPercentage, setInfoToFinalOrder } = useActions();
  const theme = useTheme();

  const selectedTipStyle = useMemo(() => ({
    width: "50px",
    height: "50px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "15px",
    color: grey[100],
  }), [theme]);

  const setNewTip = useCallback((tip) => {
    setTipPercentage(tip)
    setInfoToFinalOrder()
  }, [setTipPercentage, setInfoToFinalOrder]);

  const CustomTipTextField = useMemo(() => {
    return <TextField
      size="small"
      key="customTip"
      label="Custom tip"
      variant="outlined"
      placeholder="0"
      className={classes.customTip}
      value={selectedTip?.toString()}
      InputProps={{
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
        inputComponent: NumberMaskInput as any
      }}
      onChange={(e) => {
        const newVal = +e.target.value;
        if (isNaN(newVal)) {
          return;
        }

        setNewTip(newVal);
      }}
    />
  }, [selectedTip]);

  const Tips = useMemo(() => {
    if (!businessInfo?.tip || businessInfo?.tip.length === 0) {
      return null;
    }

    return businessInfo.tip.map((tip) => <div
      key={tip}
      onClick={() => setNewTip(tip)}
      className={classes.tipBtn}
      style={tip === selectedTip ? selectedTipStyle : { color: theme.palette.primary.main }}
    >
      {tip}%
    </div>);
  }, [businessInfo?.tip, selectedTip, setNewTip, selectedTipStyle]);

  if (!businessInfo) return <></>;

  return (
    <FormControl className={classes.tips}>

      <Typography className={classes.label}>Tip</Typography>

      <div className={classes.list}>
        {Tips}
        {CustomTipTextField}
      </div>

    </FormControl>
  );
};

export default Tips;
